<template>
  <div class="item-Q4">
    <el-container>
      <el-header style="padding: 0" height="100%">
        <vheader class="hidden-sm-and-down"></vheader>
        <ivheadera class="hidden-md-and-up"></ivheadera>
      </el-header>
    </el-container>
    <div class="main">
      <div class="w">
        <div class="content">
          <div class="title"><img src="../../assets/images/Q/Q6/header-title.jpg" alt="" /></div>
          <div class="list hidden-sm-and-down">
            <ul>
              <li @click="btn1">趣活动主页</li>
              <li @click="btn2">活动</li>
              <li @click="btn3">主办方</li>
            </ul>
            <el-popover placement="bottom" width="200" trigger="hover" content="目前仅限通过主办方认证的用户发起活动">
              <div class="btn" slot="reference" @click="btn4">
                <i class="el-icon-s-flag"></i>
                <span>免费发起活动</span>
              </div>
            </el-popover>
          </div>
          <div class="phone-list-btn hidden-md-and-up">
            <button @click="btn1">趣活动主页</button>
            <button @click="btn2">活动</button>
            <button @click="btn3">主办方</button>
            <button @click="btn4phone">
              <i class="el-icon-s-flag"></i>
              <span>免费发起活动</span>
            </button>
          </div>
        </div>
        <div class="main-info">
          <el-row>
            <el-col :xs="24" :sm="24" :md="6">
              <div class="info-one">
                <el-row>
                  <el-col :xs="12" :sm="12" :md="24" class="info-tx"><img :src="zbfinfo.images" /></el-col>
                  <el-col :xs="12" :sm="12" :md="24" class="hidden-md-and-up">
                    <div class="info-one-m">
                      <p class="tit-m">{{ zbfinfo.CNNAME == '' ? zbfinfo.ENNAME : zbfinfo.CNNAME }}</p>
                      <div class="info-look-heart m">
                        <p class="look">
                          <span></span>
                          {{ zbfinfo.NUM }}
                        </p>
                        <p class="heart">
                          <span></span>
                          {{ funcolnum }}
                        </p>
                      </div>
                      <div class="follow-btn" @click="followBtn">{{ follow === 0 ? '关注该主办方' : '已关注该主办方' }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="18">
              <div class="info-two">
                <p class="info-tit">{{ zbfinfo.CNNAME == '' ? zbfinfo.ENNAME : zbfinfo.CNNAME }}</p>
                <div class="info-look-heart">
                  <p class="look">
                    <span></span>
                    {{ zbfinfo.NUM }}
                  </p>
                  <p class="heart">
                    <span></span>
                    {{ funcolnum }}
                  </p>
                </div>
                <p class="info-der">{{ zbfinfo.SHORTCONTENT }}</p>
                <div class="info-follow">
                  <div class="follow-btn" @click="followBtn">{{ follow === 0 ? '关注该主办方' : '已关注该主办方' }}</div>
                  <p class="p-t">分享到</p>
                  <div class="social-share"></div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="main-mid">
          <div class="tit">
            <img src="../../assets/images/Q/Q4/tit.png" class="hidden-sm-and-down" />
            <img src="../../assets/images/Q/Q4/tit-m.png" class="hidden-md-and-up" />
          </div>
          <div class="main-list">
            <div class="fun-list">
              <p class="nofun-tips" v-show="funlist.length == 0">该主办方暂未发布活动</p>
              <el-row>
                <el-col :xs="24" :sm="8" :md="8" v-for="(item, index) in funlist.slice((pagecur - 1) * pagecnt, pagecur * pagecnt)" :key="index">
                  <div class="fcard-item">
                    <el-col :xs="12" :sm="24" :md="24">
                      <div class="over-hidden"><img class="item-img" width="100%" :src="item.ONE" alt="" @click="godetail(item.MAKEACTIVITY_ID, item.USERID)" /></div>
                    </el-col>
                    <el-col :xs="12" :sm="24" :md="24">
                      <div class="fcard-content">
                        <div class="fcard-txt">
                          <p>{{ item.TITLE }}</p>
                        </div>
                        <div class="fcard-icon">
                          <p>
                            <i class="el-icon-time"></i>
                            <span>{{ getTime(item.STARTTIME) }}</span>
                            -
                            <span>{{ getTime(item.ENDTIME) }}</span>
                          </p>
                          <p>
                            <i class="el-icon-location"></i>
                            {{ item.ADDRESS }}
                          </p>
                        </div>
                        <div class="fcard-foot clearfloat display-none">
                          <img :src="item.images" alt="" />
                          <span>{{ item.CNNAME }}</span>
                        </div>
                      </div>
                    </el-col>
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :total="funlist.length"
              :page-size="pagecnt"
              :current-page="pagecur"
              hide-on-single-page
              @prev-click="prev()"
            ></el-pagination>
          </div>
          <div class="main-list-m">
            <p class="nofun-tips" v-show="funlist.length == 0">该主办方暂未发布活动</p>
            <el-row v-for="(item, index) in funlist" :key="index">
              <div class="fcard-item">
                <el-col :span="12">
                  <div class="over-hidden"><img :src="item.ONE" @click="godetail(item.MAKEACTIVITY_ID, item.USERID)" /></div>
                </el-col>
                <el-col :span="12">
                  <div class="fcard-content">
                    <div class="fcard-txt">
                      <p>{{ item.TITLE }}</p>
                    </div>
                    <div class="fcard-icon">
                      <p>
                        <i class="el-icon-time"></i>
                        <span>{{ getTime(item.STARTTIME) }}</span>
                        -
                        <span>{{ getTime(item.ENDTIME) }}</span>
                      </p>
                      <p>
                        <i class="el-icon-location"></i>
                        {{ item.ADDRESS }}
                      </p>
                    </div>
                    <div class="fcard-foot clearfloat"><img :src="item.images" /></div>
                  </div>
                </el-col>
              </div>
            </el-row>
            <!-- <div class="bom-text" v-show="funlist.length != 0"><p>已经到底了</p></div> -->
          </div>
        </div>
      </div>
    </div>
    <el-footer>
      <vfooter class="hidden-sm-and-down"></vfooter>
      <ivfooter class="hidden-md-and-up"></ivfooter>
    </el-footer>
  </div>
</template>

<script>
import { initiatelist, checkCollect, delCollect, addCollect, getzbfcolnum, postfunlooknum, getzbfs } from '../../api'
import qs from 'qs'
export default {
  data () {
    return {
      sponsorId: '',
      follow: '',
      pagecur: 1,
      pagecnt: 9,
      config: {},
      zbfinfo: {},
      sponsorInfo: {},
      sponsorInfo2: {},
      funlist: [],
      funcolnum: 0
    }
  },
  inject: ['reload'],
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo == undefined || userInfo == null) {
      this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        this.$router.push('/Login')
      })
    } else {
      this.sponsorId = this.$route.query.id
      checkCollect(userInfo.userId, this.sponsorId).then(res => {
        this.follow = res.data[0]['count(USERID)']
      })
      getzbfs(this.sponsorId).then(res => {
        this.zbfinfo = res.data[0]
        var config = {
          url: window.location.href, // 网址，默认使用 window.location.href
          source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
          title: 'CityPlus主办方-' + res.data[0].CNNAME, // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
          description: '敬请关注CityPlus趣活动平台，发布精彩活动。', // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
          image: '/FH-WEB/uploadFiles/uploadImgs/20190829/adf551cfdd994f729a0f53e248d9b76c.jpg', // 图片, 默认取网页中第一个img标签
          sites: ['weibo', 'qq', 'wechat'], // 启用的站点
          // disabled: ['google', 'facebook', 'twitter'], // 禁用的站点
          wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
          wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
        }
        window.socialShare('.social-share', config)
        let datas = {
          SPONSORMX_ID: res.data[0].SPONSORMX_ID
        }
        postfunlooknum(qs.stringify(datas)).then(res => {})
        getzbfcolnum(res.data[0].SPONSOR_ID).then(res => {
          this.funcolnum = res.data[0]['count(SPONSORID)']
        })
      })
      let list = []
      initiatelist(this.sponsorId)
        .then(res => {
          for (let i = 0, len = res.data.length; i < len; i++) {
            if (res.data[i].是否显示 == 'true') {
              list.push(res.data[i])
            }
          }
          this.sponsorInfo = list[0]
          this.sponsorInfo2 = res.data[0]
          this.funlist = list
        })
    }
  },
  methods: {
    followBtn () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/Login')
        })
      } else {
        let data = {
          USERID: userInfo.userId,
          SPONSORID: this.sponsorId
        }
        if (this.follow === 0) {
          addCollect(qs.stringify(data)).then(res => {
            if (res.data.result === 'ok') {
              this.reload()
            }
          })
        } else {
          delCollect(qs.stringify(data)).then(res => {
            if (res.data.result === 'ok') {
              this.reload()
            }
          })
        }
      }
    },
    btn1 () {
      this.$router.push('/Activities')
    },
    btn2 () {
      this.$router.push('/activitylist')
    },
    btn3 () {
      this.$router.push('/sponsorlist')
    },
    btn4 () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/Login')
        })
      } else {
        this.$router.push('/create')
      }
    },
    btn4phone () {
      this.$message({
        type: 'info',
        message: '请到PC端发起活动。',
        center: true
      })
    },
    prev () {
      this.pagecur--
    },
    next () {
      this.pagecur++
    },
    getTime (timeDate) {
      var date = new Date(timeDate.replace(/-/g, '/'))
      // var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var currentTime = month + '月' + day + '日  ' + hour + ':' + minute
      return currentTime
    },
    handleCurrentChange (val) {
      this.pagecur = val
    },
    godetail (id, uid) {
      this.$router.push({
        name: 'cityQ5',
        query: {
          id: id,
          uid: uid
        }
      })
    },
    changepage(e){
      this.pagecur = e
    }
  }
}
</script>

<style scoped lang="stylus">
bg(str)
  background url(str) no-repeat center 0 / 100% 100%
.item-Q4
  .content
    .list
      .btn
        display inline-block
        padding 0.15rem 0.5rem
        background #C1DB5C
        cursor pointer
        span
          font-size 0.25rem
          color #434A3A
  .main
    padding 2.2rem 0 0 0
    .w
      .content
        .title
          display inline-block
          width 50%
          text-align left
          img
            width 73%
        .list
          display inline-block
          width 50%
          margin-top -0.5rem
          vertical-align middle
          text-align right
          ul
            display inline-block
            margin-right 0.3rem
            li
              position relative
              display inline-block
              font-size 0.3rem
              color #464646
              padding 0 0.3rem
              cursor pointer
              &:not(:last-child):after
                content ''
                position absolute
                top 10%
                right 0
                height 80%
                border-right 1px solid #464646
          .btn
            display inline-block
            padding 0.15rem 0.5rem
            background #C1DB5C
            span
              font-size 0.25rem
              color #434A3A
      .main-info
        padding 1.6rem 0 1.6rem 0
        .info-look-heart
          font-size 0.2592rem
          padding 0.2rem 0 0 0
          .look
            display inline-block
            margin 0 0.4rem 0 0
            span
              display inline-block
              width 0.2962rem
              height 0.1851rem
              bg '../../assets/images/Q/Q5/eye-icon.png'
          .heart
            display inline-block
            span
              display inline-block
              width 0.2222rem
              height 0.2037rem
              bg '../../assets/images/Q/Q4/heart.png'
        .info-one
          .info-tx
            text-align left
            img
              max-width 4.3rem
          .info-one-m
            text-align left
            margin 0 0 0 -0.8rem
            padding 0.44rem 0 0 0
            .tit-m
              color #373737
              font-size 0.5185rem
              overflow hidden
              text-overflow ellipsis
              white-space nowrap
            .info-look-heart
              &.m
                padding 0.2rem 0 0.4rem 0
                .look
                  margin 0 1.2rem 0 0
            .follow-btn
              cursor pointer
              color #fff
              font-size 0.4444rem
              background #a5cd39
              padding 0.2rem 0.5rem
              text-align center
        .info-two
          text-align left
          color #8d8d8d
          .info-tit
            color #373737
            font-size 0.4444rem
          .info-der
            padding 0.2rem 0 0 0
            font-size 0.2962rem
            height 1.9rem
            overflow hidden
            -webkit-line-clamp 4
            display -webkit-box
            -webkit-box-orient vertical
            margin-bottom 0.35rem
          .info-follow
            padding 0 0 0 0
            .follow-btn
              display inline-block
              font-size 0.2962rem
              color #fff
              padding 0.2rem 0.8rem
              background #a5cd39
              cursor pointer
            .p-t
              display inline-block
              padding 0 0.2rem 0 0.4rem
            .social-share
              display inline-block
      .over-hidden
        overflow hidden
        height 3.7037rem
        margin-bottom 0.1rem
        .item-img
          display block
          height 100%
      .main-mid
        padding 0 0 1rem 0
        .tit
          text-align left
          padding 0 0 0.4rem 0
        .main-list-m
          display none
        .fun-list
          .fcard-item
            position relative
            display inline-block
            background #fff
            margin-right 0.6rem
            margin-bottom 0.6rem
            text-align left
            border 0.0185rem solid #d5d5d5
            min-width 95%
            width 95%
            .item-img
              transition all 0.3s ease-out 0s
              cursor pointer
              display block
              &:hover
                transform scale(1.1)
            .fcard-content
              padding 0 0.4rem
              .fcard-txt
                position relative
                color #474747
                font-size 0.3rem
                margin-left 0.15rem
                height 0.8rem
                display flex
                align-items center
                p
                  padding-left 0.4rem
                  position relative
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  &:before
                    content ''
                    position absolute
                    top 10%
                    left 0
                    height 80%
                    border-right 3px solid #A6CE36
              .fcard-icon
                color #d3d3d3
                font-size 0.25rem
                margin-top 0.2rem
                margin-bottom 0.1rem
                p
                  width 100%
                  overflow hidden
                  text-overflow ellipsis
                  white-space nowrap
                  i
                    color #BFDA5D
                    font-size 0.3rem
                    margin-right 0.3rem
              .fcard-foot
                display flex
                align-items center
                padding 0.14rem 0
                border-top 1px solid #d3d3d3
                img, span
                  float left
                span
                  margin-left 0.3rem
@media screen and (max-width: 990px)
  .item-Q4
    .main
      padding 2.2rem 0 0
      .w
        .content
          padding 0 0.37rem 0
          .title
            width 100%
            img
              width 55%
        .info-two
          .info-follow
            display none
        .main-info
          padding 1.6rem 0.37rem 0.2rem
          .info-one
            .info-tx
              img
                max-width 3.3rem
          .info-two
            margin 0
            .info-tit, .info-look-heart
              display none
        .main-mid
          background #f4f4f4
          padding 0.4rem 0 0 0
          .tit
            padding 0 0.37rem 0
            img
              max-width 35%
          .main-list
            display none
          .main-list-m
            display block
            padding 0 0.37rem 0
            border-bottom 0.4rem solid #f4f4f4
            max-height 12rem
            overflow auto
            .fcard-item
              display flex
              justify-content center
              align-items center
              margin-top 0.3rem
              background #fff
              .over-hidden
                display grid
                height 2.5rem
                margin-bottom 0
                img
                  height 2.5rem
                  width 100%
              .fcard-content
                padding 0 0.4rem
                position relative
                text-align left
                .fcard-txt
                  position relative
                  color #474747
                  font-size 0.3rem
                  margin-left 0.15rem
                  p
                    padding-left 0.4rem
                    position relative
                    &::before
                      content ''
                      position absolute
                      top 10%
                      left 0
                      height 80%
                      border-right 3px solid #a6ce36
                .fcard-icon
                  color #d3d3d3
                  font-size 0.25rem
                  margin-top 0.5rem
                  margin-bottom 0.1rem
                  p
                    overflow hidden
                    text-overflow ellipsis
                    white-space nowrap
                    max-width 3rem
                    i
                      color #bfda5d
                      font-size 0.3rem
                      margin-right 0.3rem
                .fcard-foot
                  display flex
                  align-items center
                  padding 0
                  border none
                  img
                    position absolute
                    right 6%
                    bottom 4%
                    width 0.75rem
                    float left
            .bom-text
              color #c0c0c0
              border-top 0.0185rem solid #c0c0c0
              margin 0.8rem 0 0 0
              p
                background #f4f4f4
                display inline-block
                position relative
                top -0.2rem
                padding 0 0.2rem
    .phone-list-btn
      button
        width 48%
        padding 2.5% 0
        border 1px solid #A5CD39
        background none
        margin-top 4%
        &:nth-child(2n)
          margin-left 4%
        &:last-child
          background #a5cd39
          color #fff
</style>
<style lang="stylus">
 .item-Q4{
   .nofun-tips{
     color #373737
     font-size 0.4444rem
     text-align center
     padding 1rem 0
   }
   .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
     margin: 0;
     border-radius: 0;
     border 0.0185rem solid #e2e2e2
     background #fff
     padding 0 .24rem
   }
   .el-pagination.is-background .el-pager li:not(.disabled).active{
     background #a5cd53
     border 0.0185rem solid #a5cd53
   }
.main-list{
	.el-icon-arrow-left:before{
	  content '上一页'
	  font-weight normal
	}
	.el-icon-arrow-right:before{
	  content '下一页'
	  font-weight normal
	}
}
 }
</style>
